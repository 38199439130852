const costAdjustDicts = {
  // 状态下拉项
  statusList: [
    { label: '审核中', value: '1' },
    { label: '已完成', value: '2' },
    { label: '审核驳回', value: '3' },
    { label: '已撤销', value: '4' },
  ],

  // 调整类型下拉项
  costAdjustStatusList: [
    { label: '出库成本调整', value: '1' },
    { label: '入库成本调整', value: '2' },
  ],

  // 单据来源
  costAdjustTypeList: [
    { label: '自动生成', value: '1' },
    { label: '手动新增', value: '2' },
  ],

  // 显示调整产品
  columnsComplex: [
    {
      label: '调整单号',
      prop: 'costAdjustNumber',
      isShow: true,
      checkable: false,
      width: 150,
    },
    {
      label: '入库批次',
      prop: 'lotNo',
      isShow: true,
      checkable: true,
      width: 150,
    },
    {
      label: '入库类型',
      prop: 'warehouseType',
      isShow: true,
      checkable: true,
    },
    {
      label: '调整类型',
      prop: 'costWarehouseType',
      isShow: true,
      checkable: true,
    },
    {
      label: '单据来源',
      prop: 'costAdjustType',
      isShow: true,
      checkable: true,
    },
    {
      label: '产品编码',
      prop: 'sku',
      isShow: true,
      checkable: true,
    },
    {
      label: '产品名称',
      prop: 'productCn',
      isShow: true,
      checkable: true,
      width: 180,
    },
    {
      label: '规格',
      prop: 'specification',
      isShow: true,
      checkable: true,
    },
    {
      label: '数量',
      prop: 'warehouseInNum',
      isShow: true,
      checkable: true,
    },
    {
      label: '调整金额',
      prop: 'adjustPrice',
      isShow: true,
      checkable: true,
    },
    {
      label: '单价',
      prop: 'priceExcludingTax',
      isShow: true,
      checkable: true,
    },
    {
      label: '总成本',
      prop: 'totalPriceExcluding',
      isShow: true,
      checkable: true,
    },
    {
      label: '调整后单价',
      prop: 'priceExcludingTaxed',
      isShow: true,
      checkable: true,
    },
    {
      label: '调整后总成本',
      prop: 'totalPriceExcluded',
      isShow: true,
      checkable: true,
    },
    {
      label: '状态',
      prop: 'costAdjustStatus',
      isShow: true,
      checkable: false,
    },
    {
      label: '创建人',
      prop: 'creatorName',
      isShow: true,
      checkable: true,
    },
    {
      label: '创建时间',
      prop: 'createTime',
      isShow: true,
      checkable: true,
      sortable: true,
      isSort: false,
      sortType: null,
    },
    {
      label: '产品备注',
      prop: 'remark',
      isShow: true,
      checkable: true,
    },
  ],

  // 不显示调整产品
  columnsSimple: [
    {
      label: '调整单号',
      prop: 'costAdjustNumber',
      isShow: true,
      checkable: false,
    },
    {
      label: '调整类型',
      prop: 'costWarehouseType',
      isShow: true,
      checkable: true,
    },
    {
      label: '单据来源',
      prop: 'costAdjustType',
      isShow: true,
      checkable: true,
    },
    {
      label: '状态',
      prop: 'costAdjustStatus',
      isShow: true,
      checkable: false,
    },
    {
      label: '创建人',
      prop: 'creatorName',
      isShow: true,
      checkable: true,
    },
    {
      label: '创建时间',
      prop: 'createTime',
      isShow: true,
      checkable: true,
      sortable: true,
      isSort: false,
      sortType: null,
    },
    {
      label: '备注说明',
      prop: 'remark',
      isShow: true,
      checkable: true,
    },
  ],

  // 状态名称转化
  getCostAdjustStatusName(n) {
    const dicts = {
      1: '审核中',
      2: '已完成',
      3: '审核驳回',
      4: '已撤销'
    }

    return dicts[n]
  },

  // 状态tags
  getStatusTags(n) {
    const dicts = {
      1: 'warning',
      2: 'success',
      3: 'danger',
      4: 'primiry'
    }

    return dicts[n]
  },

  // 入库类型名称转化
  getWarehouseTypeName(n) {
    const dicts = {
      0: '采购入库',
      1: '退货入库',
      2: '其他入库',
      3: '销售出库',
      4: '退货出库',
      5: '其他出库',
      10: '调拨入库',
      11: '盘盈入库',
      12: "调拨出库",
      13: "领料出库",
      14: "退料入库",
      15: "加工入库",
      16: "盘亏出库",
    }

    return dicts[n]
  },

  // 调整类型名称转化
  getCostWarehouseTypeName(n) {
    const dicts = {
      1: '出库成本调整',
      2: '入库成本调整'
    }

    return dicts[n]
  },

  // 单据来源名称转化
  getCostAdjustTypeName(n) {
    const dicts = {
      1: '自动生成',
      2: '手动新增'
    }

    return dicts[n]
  },
}

export default costAdjustDicts
